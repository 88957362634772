<template>
  <v-navigation-drawer
    class="cart-panel mr-lg-5"
    v-model="drawerClone"
    right
    :permanent="$vuetify.breakpoint.mdAndUp ? true : false"
    :width="$vuetify.breakpoint.lgAndUp ? 400 : 300"
    clipped
    fixed
    app
    ref="navigation-drawer"
  >
    <!-- Salesman identification and Icon to configure Sale's properties and set Cart actions-->
    <div v-if="cartSalesman" class="cart-panel-salesman">
      <v-list-item @click="displayDialogCheckoutCartSalesman">
        <v-list-item-icon>
          <v-icon>fa-user-tie</v-icon>
        </v-list-item-icon>

        <v-list-item-title>{{ cartSalesman.data.SalesmanShortName }}</v-list-item-title>

        <!-- Sale's properties and actions -->
        <v-menu
          offset-y
          v-if="
            cartType !== 'return' &&
            cartType !== 'returnTotal' &&
            (cartCheckoutInProgress ||
              (!enableEditions && hasSelectedSalesmanAccessToSaleNoVat) ||
              hasSelectedSalesmanAccessToSaleFromStockExit)
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon small>{{ "fas fa-ellipsis-v" }}</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-if="cartCheckoutInProgress || (!enableEditions && hasSelectedSalesmanAccessToSaleNoVat)">
              <v-btn
                v-if="!enableEditions && !cartVatExempt && hasSelectedSalesmanAccessToSaleNoVat"
                @click="cartVatExempt = true"
                color="blue lighten-2"
                text
              >
                {{ $vuetify.lang.t("$vuetify.applyVatExempt") }}
              </v-btn>
              <v-btn
                v-if="!enableEditions && cartVatExempt && hasSelectedSalesmanAccessToSaleNoVat"
                @click="cartVatExempt = false"
                color="blue lighten-2"
                text
              >
                {{ $vuetify.lang.t("$vuetify.removeVatExempt") }}
              </v-btn>
              <v-btn
                v-if="cartType !== 'return' && cartType !== 'returnTotal' && cartCheckoutInProgress"
                @click="cartPrintAndSave"
                color="blue lighten-2"
                text
              >
                {{ $vuetify.lang.t("$vuetify.cancelAndPrint") }}
              </v-btn>
            </v-list-item>
            <v-list-item v-if="hasSelectedSalesmanAccessToSaleFromStockExit">
              <v-btn
                @click="
                  () => {
                    displaySaleFromStockExitDialog = true;
                  }
                "
                color="blue lighten-2"
                text
              >
                {{ $vuetify.lang.t("$vuetify.fromStockExit") }}
              </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
        <!-- END Sale's properties -->
      </v-list-item>
    </div>

    <!-- A message to alert the User that:
    1 - the Cart has the Property Vat Exempt active;
    2 - the Cart is applying Prices not from the Default Price Range -->
    <div v-if="cartVatExempt" class="cart-panel-alert-sale-properties-alert">
      <span>{{ $vuetify.lang.t("$vuetify.appliedVatExempt") }} </span>
    </div>
    <div v-if="cartZoneVerbose" class="cart-panel-alert-sale-properties-info">
      <span><br />{{ $vuetify.lang.t("$vuetify.sale") }} {{ cartZoneVerbose }}</span>
    </div>

    <!-- List of Items, quantities, prices and discounts   -->
    <div class="cart-panel-items" v-bind:class="{ checkout: cartCheckoutInProgress }">
      <ItemsList
        :items="cartItems"
        :enableEditions="!this.enableEditions"
        :enableDialogEdition="this.cartType !== 'return' && !this.enableEditions"
        :enableChangeQuantityPlus="this.cartType !== 'return'"
      ></ItemsList>
    </div>

    <div class="cart-panel-actions" v-bind:class="{ checkout: cartCheckoutInProgress }">
      <v-divider class="my-2 grey"></v-divider>

      <!-- Buttons to Remove the Items from the Cart, to apply Discounts and to Save the Cart -->
      <v-btn :disabled="cartTotalItems === 0" plain @click="cancel" class="pa-3">
        {{ $vuetify.lang.t("$vuetify.clean") }}
      </v-btn>

      <v-btn
        v-if="cartType !== 'return' && cartType !== 'returnTotal'"
        plain
        @click="save"
        color="orange"
        class="pa-3"
        :disabled="cartTotalItems === 0"
      >
        {{ $vuetify.lang.t("$vuetify.save") }}
      </v-btn>

      <v-btn
        v-if="!cartCheckoutInProgress && cartType !== 'return' && cartType !== 'returnTotal'"
        plain
        @click="openGlobalDiscountDialog()"
        color="red"
        class="pa-3"
        :disabled="cartTotalItems === 0"
      >
        {{ $vuetify.lang.t("$vuetify.discountShort") }}
      </v-btn>
      <!-- END Buttons to Remove the Items from the Cart, to apply Discounts and to Save the Cart -->

      <v-spacer class="my-4"></v-spacer>

      <v-btn
        v-if="!cartCheckoutInProgress"
        block
        :disabled="cartTotalItems === 0"
        @click="submit"
        :class="cartType === 'return' || cartType === 'returnTotal' ? 'red cart-panel-pay' : 'blue cart-panel-pay'"
        x-large
      >
        <small v-if="cartType !== 'return' && cartType !== 'returnTotal'">{{ $vuetify.lang.t("$vuetify.pay") }}</small>
        <small v-else>{{ $vuetify.lang.t("$vuetify.return") }}</small>
        <span class="amount">{{ cartTotalAmount }}{{ currencySymbol }}</span>
      </v-btn>

      <!-- Buttons to "Finalize" the Cart -->
      <v-spacer class="my-7"></v-spacer>
      <v-select
        v-if="cartCheckoutInProgress && docTypes.length > 1"
        label="Tipo de Documento"
        v-model="activeDocType"
        class="mt-2"
        :items="docTypes"
        :disabled="!cartCheckoutSubmitAvailable"
        solo
      ></v-select>

      <v-btn
        v-if="cartType !== 'return' && cartType !== 'returnTotal' && cartCheckoutInProgress"
        block
        :disabled="!cartCheckoutSubmitAvailable"
        @click="$root.$emit('submitCart')"
        class="primary cart-panel-pay"
        x-large
      >
        <small>{{ $vuetify.lang.t("$vuetify.end") }}</small>
        <span class="amount">{{ cartTotalAmount }}{{ currencySymbol }}</span>
      </v-btn>

      <v-btn
        v-if="(cartType === 'return' || cartType === 'returnTotal') && cartCheckoutInProgress"
        block
        :disabled="!cartCheckoutSubmitAvailable"
        @click="$root.$emit('submitCart')"
        class="primary cart-panel-pay"
        x-large
      >
        <small>{{ $vuetify.lang.t("$vuetify.return") }}</small>
        <span class="amount">{{ cartTotalAmount }}{{ currencySymbol }}</span>
      </v-btn>
      <!-- END Buttons to "Finalize" the Cart -->

      <v-dialog v-model="displayConfirmCancelSaveDialog" persistent max-width="250px">
        <ConfirmAction
          :title="confirmCancelSaveTitle"
          :confirmMessage="confirmCancelSaveMessage"
          :displayCancelButton="true"
          @close-dialog="closeCancelSaveDialog"
          @confirm-action="confirmCancelSave"
        ></ConfirmAction>
      </v-dialog>

      <v-dialog v-model="displaySaleFromStockExitDialog" max-width="400px">
        <v-form ref="form" @submit.prevent="confirmStockExitDialog">
          <v-card>
            <v-card-title class="title">{{ $vuetify.lang.t("$vuetify.fromStockExit") }}</v-card-title>
            <v-card-text>
              <v-text-field
                v-model="codeStockExit"
                :label="$vuetify.lang.t('$vuetify.code')"
                type="number"
                required
                autofocus
              ></v-text-field>
              <div class="red--text" v-if="errorStockExit">{{ errorStockExit }}</div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="closeStockExitDialog"> {{ $vuetify.lang.t("$vuetify.cancel") }}</v-btn>
              <v-btn
                class="primary"
                @click="confirmStockExitDialog()"
                :disabled="codeStockExit === null || codeStockExit === ''"
              >
                <v-progress-circular v-if="loadingStockExit" color="white" indeterminate></v-progress-circular>
                <span v-else>
                  {{ $vuetify.lang.t("$vuetify.ok") }}
                </span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <!-- Global Discount Implementation -->
      <v-dialog v-model="displayGlobalDiscountDialog" max-width="400px">
        <v-form ref="form" v-model="globalDiscountFormValid" lazy-validation v-if="globalDiscountFormReady">
          <v-card>
            <v-card-title class="title">{{ $vuetify.lang.t("$vuetify.globalDiscount") }}</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="globalDiscountValue"
                    :label="$vuetify.lang.t('$vuetify.discount')"
                    type="number"
                    :rules="discountRules"
                    required
                    v-on:change="$refs.form.validate()"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-select
                    v-model="globalDiscountType"
                    :label="$vuetify.lang.t('$vuetify.discountType')"
                    :items="discountTypes"
                    item-text="text"
                    item-value="value"
                    required
                    v-on:change="$refs.form.validate()"
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="closeGlobalDiscountDialog()"> {{ $vuetify.lang.t("$vuetify.cancel") }}</v-btn>
              <v-btn class="primary" @click="confirmGlobalDiscountAction()" :disabled="!globalDiscountFormValid"
                >{{ $vuetify.lang.t("$vuetify.ok") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
      <!-- END Global Discount Implementation -->
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import ItemsList from "@/components/ItemsList.vue";
import ConfirmAction from "@/components/ConfirmAction.vue";
import {
  getErrorMessage,
  isValidReturnDocType,
  isValidSaleDocType,
  PERM_SALE_NO_VAT,
  PERM_SALE_FROM_STOCK_EXIT,
} from "@/_helpers";
import { salesServices } from "@/services";

export default {
  name: "Cart",
  components: { ItemsList, ConfirmAction },
  props: {
    drawer: Boolean,
  },
  data() {
    return {
      drawerClone: this.drawer,
      displayConfirmCancelSaveDialog: false,
      confirmAction: null,
      confirmCancelSaveTitle: null,
      confirmCancelSaveMessage: null,
      // Global Discount Implementation
      //  flags used on the form of the Global Discount. These flags allow the submission of the form
      globalDiscountFormValid: true,
      globalDiscountFormReady: false,
      //  flag which indicates whether the Global Discount Dialog should be displayed
      displayGlobalDiscountDialog: false,
      // variables to store the Discount's Value and the chosen Discount's Type
      //  and the default value to be displayed on the Discount's Type field
      globalDiscountValue: 0,
      globalDiscountType: "value",
      //  the available Values for the Discount Type
      discountTypes: [],
      docTypes: [],
      isReturn: false,
      // sale from justified exit dialog
      displaySaleFromStockExitDialog: false,
      codeStockExit: null,
      errorStockExit: null,
      loadingStockExit: false,
    };
  },
  methods: {
    submit() {
      //daniel.silva temporário tiger
      if (this.cartSalesman.data.CardId === "9999") {
        this.$router.push({ name: "shop-cart-selfcheckout-details-client" }).catch(() => {
          /* NavigationDuplicated if the "Pay" button is clicked again */
        });
      } else {
        if (this.cartType === "return" || this.cartType === "returnTotal") {
          this.$router.push({ name: "shop-cart-checkout-return-details" }).catch(() => {
            /* NavigationDuplicated if the "Pay" button is clicked again */
          });
        } else {
          this.$router.push({ name: "shop-cart-checkout-details" }).catch(() => {
            /* NavigationDuplicated if the "Pay" button is clicked again */
          });
        }
      }
      this.$store.dispatch("cart/setCheckoutCartStatus", "inProgress");
    },
    cancel() {
      this.confirmAction = "cancel";
      this.displayConfirmCancelSaveDialog = true;
      this.confirmCancelSaveTitle = this.$vuetify.lang.t("$vuetify.msgAreYouSure");
      this.confirmCancelSaveMessage = this.$vuetify.lang.t("$vuetify.msgAreYouSureRemoveItems");
    },
    save() {
      this.confirmAction = "save";
      this.displayConfirmCancelSaveDialog = true;
      this.confirmCancelSaveTitle = this.$vuetify.lang.t("$vuetify.msgAreYouSure");
      this.confirmCancelSaveMessage = this.$vuetify.lang.t("$vuetify.msgAreYouSureSuspendSale");
    },
    closeCancelSaveDialog() {
      this.displayConfirmCancelSaveDialog = false;
    },
    /**
     * This method is called when the User uses the option of:
     * 1 - cleaning the cart ("Limpar")
     * 2 - saving the cart to use it later ("Guardar").
     *
     * Number 1 expected behaviour:
     * 1.1 removes all the Items from the Cart
     * 1.2 does not clean the Salesman assigned to the Sale
     * 1.3 redirects the User to the Catalog interface
     *
     * Number 2 expected behaviour:
     * 2.1 adds the Cart to the Saved Carts
     * 2.2 removes the Salesman assigned to the Sale
     * 2.3 redirects the User to the Catalog interface
     * This action can be performed when the user is already finishing the Sale, on the CheckoutCart interface.
     * When this happens it is necessary to save information (Client data for example) which is available only on the
     *  CheckoutCart component.
     *  2.4 Therefore, for this scenario, an event is emitted to the CheckoutCart component.
     * */
    confirmCancelSave() {
      // 1 - cleaning the cart
      if (this.confirmAction && this.confirmAction === "cancel") {
        // 1.1 removes all the Items from the Cart
        this.$store.dispatch("cart/setCartEmpty");
      }
      // 2 - saving the cart to use it later.
      else if (this.confirmAction && this.confirmAction === "save") {
        // 2.4 - since some of the necessary information is available only on the CheckoutCart,
        //  an event is emitted and caught by the CheckoutCart component.
        if (this.cartCheckoutInProgress) {
          this.$root.$emit("cancelAndSaveCart");
        } else {
          let customer = null;
          let extraIdentifier = null;
          let selectedSalesman = this.cartSalesman.data.SalesmanShortName;
          // 2.1 adds the Cart to the Saved Carts
          this.$store.dispatch("cart/saveActiveCart", {
            customer,
            extraIdentifier,
            selectedSalesman,
          });
          // 2.2 removes the Salesman assigned to the Sale
          this.$store.dispatch("salesmen/setSelectedSalesman", undefined);
        }
      }

      //1.3 and 2.3 Redirects the User to the Catalog interface
      if (this.$router.currentRoute.name !== "catalog") {
        //@modified ana.castro 2022.12.06 SAFO-47
        // this.$router.push({ name: "catalog" });
        this.$router.push({ name: "home" });
      }

      this.displayConfirmCancelSaveDialog = false;
    },
    closeStockExitDialog() {
      this.displaySaleFromStockExitDialog = false;
      this.codeStockExit = null;
      this.errorStockExit = null;
    },
    confirmStockExitDialog() {
      if (this.loadingStockExit) return;

      let parameters = {
        code: this.codeStockExit.trim(),
      };

      this.loadingStockExit = true;
      salesServices.getStockExit(parameters).then(
        (result) => {
          if (result.count === 1) {
            this.closeStockExitDialog();
            this.$store.dispatch("cart/setCartEmpty");

            result.results[0]["TransactionDetails"].forEach((detail) => {
              let quantity = detail["Quantity"];
              let searchTerm = detail["ItemId"];
              let offset = 0;
              let limit = 1;

              this.$store.dispatch("items/getItemsByExactSearch", { searchTerm, offset, limit }).then((item) => {
                if (item.length === 1 && item[0].status === 1) {
                  let itemId = item[0]["id"];
                  let parentItemId = item[0]["parentItem"];
                  this.$store.dispatch("cart/addToCart", { itemId, parentItemId, quantity });
                }
              });
            });
          } else {
            this.errorStockExit = this.$vuetify.lang.t("$vuetify.notFoundStockExit");
          }
          this.loadingStockExit = false;
        },
        (error) => {
          this.loadingStockExit = false;
          this.errorStockExit = this.$vuetify.lang.t("$vuetify.errorStockExit");
          getErrorMessage(error, this.$vuetify.lang.t("$vuetify.errorStockExit"), this);
        }
      );
    },
    // Global Discount Implementation
    openGlobalDiscountDialog() {
      this.displayGlobalDiscountDialog = true;
      this.globalDiscountFormReady = true;
    },
    closeGlobalDiscountDialog() {
      this.displayGlobalDiscountDialog = false;
      this.globalDiscountFormReady = false;
    },
    confirmGlobalDiscountAction() {
      this.$store.dispatch("cart/setCartGlobalDiscount", {
        discountValue: this.globalDiscountValue,
        discountType: this.globalDiscountType,
      });

      this.displayGlobalDiscountDialog = false;
      this.globalDiscountFormValid = false;
      this.globalDiscountFormReady = false;
      this.globalDiscountValue = 0;
      this.globalDiscountType = "value";
    },
    //END Global Discount Implementation

    /**
     * Change Salesman Assigned to the Cart Implementation
     */
    displayDialogCheckoutCartSalesman() {
      this.$store.dispatch("salesmen/setDisplaySelectSalesman", { status: true });
    },

    cartPrintAndSave() {
      this.$root.$emit("submitCartPrintAndSave");
    },

    getAllDocTypes() {
      this.$store.getters["docTypes/getAll"].then(
        (result) => {
          result.forEach((r) => {
            const validator = this.isReturn ? isValidReturnDocType : isValidSaleDocType;
            if (validator(r.nature))
              this.docTypes.push({
                text: r.description,
                value: r.nature,
              });
          });
        },
        (error) => {
          getErrorMessage(error, "Erro a obter Tipos de Documentos");
        }
      );
    },
  },
  computed: {
    discountRules: function () {
      var errors = [];

      errors.push(
        (v) => v !== undefined || this.$vuetify.lang.t("$vuetify.mandatoryField"),
        (v) =>
          this.globalDiscountType !== "percentage" ||
          (v && parseFloat(v) <= 100 && parseFloat(v) >= 0) ||
          this.$vuetify.lang.t("$vuetify.valuesBetween0to100"),
        (v) =>
          this.globalDiscountType !== "value" ||
          (v && parseFloat(v) >= 0 && parseFloat(v) <= this.cartTotalAmountWithoutDiscount) ||
          this.$vuetify.lang.t("$vuetify.valuesBetween0toX") + this.cartTotalAmountWithoutDiscount
      );

      return errors;
    },
    cartVatExempt: {
      get: function () {
        return this.$store.getters["cart/cartVatExempt"];
      },
      set: function (newValue) {
        this.$store.dispatch("cart/setCartVatExempt", newValue);
      },
    },
    activeDocType: {
      get: function () {
        if (this.isReturn) return this.$store.getters["docTypes/getActiveReturn"]();
        else return this.$store.getters["docTypes/getActiveSale"]();
      },
      set: function (newValue) {
        if (this.isReturn) return this.$store.dispatch("docTypes/setActiveReturn", newValue);
        else return this.$store.dispatch("docTypes/setActiveSale", newValue);
      },
    },
    hasSelectedSalesmanAccessToSaleNoVat() {
      return this.$store.getters["salesmen/validateSelectedSalesmanAccessToPermission"](PERM_SALE_NO_VAT);
    },
    hasSelectedSalesmanAccessToSaleFromStockExit() {
      return this.$store.getters["salesmen/validateSelectedSalesmanAccessToPermission"](PERM_SALE_FROM_STOCK_EXIT);
    },
    ...mapGetters({
      cartItems: "cart/cartItems",
      cartType: "cart/cartType",
      cartRefDocument: "cart/cartRefDocument",
      cartTotalItems: "cart/cartSize",
      cartTotalAmount: "cart/cartTotalAmount",
      cartTotalAmountWithoutDiscount: "cart/cartTotalAmountWithoutDiscount",
      cartCheckoutInProgress: "cart/cartCheckoutInProgress",
      cartCheckoutSubmitAvailable: "cart/cartCheckoutSubmitAvailable",
      cartSalesman: "salesmen/getSelectedSalesman",
      cartZoneVerbose: "cart/cartZoneVerbose",
      currencySymbol: "configs/currencySymbol",
      enableEditions: "configs/saleAreaBlocked",
    }),
  },
  watch: {
    drawer() {
      this.drawerClone = this.drawer;
    },
    drawerClone() {
      if (this.drawerClone !== this.drawer) {
        this.$emit("changeDrawer", this.drawerClone);
      }
    },
    cartItems() {
      let element = this.$refs["navigation-drawer"];
      element = element.$el.querySelector(".v-navigation-drawer__content");
      element.scrollTop = element.scrollHeight;
    },
    cartType() {
      this.isReturn = this.cartType === "return" || this.cartType === "returnTotal";
      this.docTypes = [];
      this.getAllDocTypes();
    },
  },
  mounted() {
    //A Return Document which is based on a Sale Document which has Menu Items,
    // always (!!) forces to return all (!!) the Items referenced on the Sale Document.
    //Therefore, the cart section (the sale area) can not be edited.
    if (this.cartType === "returnTotal") {
      this.$store.dispatch("configs/setSaleAreaBlocked", true);
    } else {
      this.$store.dispatch("configs/setSaleAreaBlocked", false);
    }

    this.discountTypes = [
      { value: "value", text: "€ " + this.$vuetify.lang.t("$vuetify.valuePay") },
      { value: "percentage", text: "% (" + this.$vuetify.lang.t("$vuetify.percentage") + ")" },
    ];

    this.isReturn = this.cartType === "return" || this.cartType === "returnTotal";
    this.getAllDocTypes();
  },
};
</script>

<style scoped></style>
