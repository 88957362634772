<!-- This component is used only by the AppLayout.vue.
It is used to select the Salesman which will be assigned to a Sale.
1 - it displays a list of active Salesman (their name)
2 - once a Salesman is selected, and in case he has a defined password, a dialog is displayed.
The user might insert the Salesman's password.
3 - if the insert password is correct or if the Salesman does not have a password, the set-sale-salesman event
is emitted.
Note: there is a "Master" password which can be used, mainly for testing purposes.
The "Master" password definition uses today's year, day and minute: yyyyddMM
-->
<template>
  <div id="salesmen-list-view">
    <v-card>
      <v-card-title class="title">{{ title }}</v-card-title>
      <v-card-text>
        <v-list v-if="salesmen.length > 0" two-line class="overflow-y-auto salesmans-list">
          <v-card max-width="450" class="mx-auto">
            <v-list-item-group>
              <template v-for="(item, index) in salesmen">
                <v-list-item :key="item.id" @click="selectSalesman(item)">
                  <template>
                    <v-list-item-avatar>
                      <span class="item-card-initials">
                        {{
                          item.name
                            .split(" ")
                            .reduce((result, currentWord) => result + currentWord.charAt(0).toUpperCase(), "")
                            .substring(0, 2)
                        }}
                      </span>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.name"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
                <v-divider v-if="index < salesmen.length - 1" :key="index"></v-divider>
              </template>
            </v-list-item-group>
          </v-card>
        </v-list>
        <div v-if="salesmen.length === 0" class="text-center">
          <div>{{ $vuetify.lang.t("$vuetify.msgOpenEmployeeSessions") }}</div>
          <v-spacer class="my-2"></v-spacer>
          <v-btn class="primary" text @click="redirectToSalesmenSessions()">
            {{ $vuetify.lang.t("$vuetify.salesmenSessionOpen") }}</v-btn
          >
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="closeDialogSalesman()"> {{ $vuetify.lang.t("$vuetify.cancel") }}</v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="displayDialogSalesmanPassword" max-width="300px">
      <v-card>
        <v-card-title class="title">{{ $vuetify.lang.t("$vuetify.password") }}</v-card-title>
        <v-card-text>
          <v-text-field
            v-if="displayDialogSalesmanPassword"
            autofocus
            v-model="salesmenPassword"
            :append-icon="showPassword ? 'fal fa-eye' : 'fal fa-eye-slash'"
            ref="salesmanPassword"
            :type="showPassword ? 'text' : 'password'"
            outlined
            dense
            @click:append="showPassword = !showPassword"
            @keydown.enter="validateSalesmanAndPassword"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDialogSalesmanPassword()"> {{ $vuetify.lang.t("$vuetify.cancel") }}</v-btn>
          <v-btn class="primary" text @click="validateSalesmanAndPassword()">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import bcrypt from "bcryptjs";
import { getErrorMessage } from "@/_helpers";

export default {
  name: "SalesmenList",
  props: {
    title: String,
  },
  data: () => ({
    salesmen: [],
    salesman: null,
    displayDialogSalesmanPassword: false,
    showPassword: false,
    salesmenPassword: null,
  }),
  methods: {
    getSalesmenMasterPassword() {
      let currentDate = new Date();
      let day = "" + currentDate.getDate();
      let minutes = "" + currentDate.getMinutes();
      if (day.length < 2) day = "0" + day;
      if (minutes.length < 2) minutes = "0" + minutes;
      return currentDate.getFullYear() + day + minutes;
    },
    getAllSalesmen() {
      this.$store.dispatch("salesmen/getAllWithOpenSession").then((result) => {
        this.salesmen = result;
      });
    },
    selectSalesman(salesman) {
      this.salesman = salesman;
      this.$store.dispatch("salesmen/get", this.salesman.id).then(
        (result) => {
          if (result && result[0] && result[0]["data"]["SalesmanPasswordE"]) {
            this.displayDialogSalesmanPassword = true;
          } else {
            this.$emit("set-sale-salesman", this.salesman);
          }
        },
        (error) => {
          getErrorMessage(error, this.$vuetify.lang.t("$vuetify.errorInfoEmployee") + this.salesman, this);
        }
      );
    },
    closeDialogSalesman() {
      this.$store.dispatch("salesmen/setDisplaySelectSalesman", { status: false });
    },
    closeDialogSalesmanPassword() {
      this.displayDialogSalesmanPassword = false;
      this.salesmenPassword = null;
    },
    validateSalesmanAndPassword() {
      let salesmenMasterPassword = this.getSalesmenMasterPassword();
      let password_valid = false;
      this.$store.dispatch("salesmen/get", this.salesman.id).then(
        (result) => {
          if (result && result[0]) {
            if (this.salesmenPassword === salesmenMasterPassword) {
              password_valid = true;
            } else {
              if (
                result[0]["data"]["SalesmanPasswordE"] &&
                this.salesmenPassword &&
                bcrypt.compareSync(this.salesmenPassword, result[0]["data"]["SalesmanPasswordE"])
              ) {
                password_valid = true;
              } else if (!result[0]["data"]["SalesmanPasswordE"] && !this.salesmenPassword) {
                password_valid = true;
              } else {
                password_valid = false;
              }
            }
            if (password_valid) {
              this.displayDialogSalesmanPassword = false;
              this.salesmenPassword = null;
              this.$emit("set-sale-salesman", this.salesman);
            } else {
              this.displayDialogSalesmanPassword = false;
              this.$store.dispatch(
                "alert/error",
                this.$vuetify.lang.t("$vuetify.errorValidatingCredentials") +
                  " " +
                  this.$vuetify.lang.t("$vuetify.tryAgain")
              );
            }
            this.salesmenPassword = null;
          }
        },
        (error) => {
          getErrorMessage(error, this.$vuetify.lang.t("$vuetify.errorInfoEmployee") + this.salesman, this);
        }
      );
    },
    redirectToSalesmenSessions() {
      this.$router.push({ name: "salesmen-sessions" });
      this.closeDialogSalesman();
    },
  },
  mounted() {
    this.getAllSalesmen();
  },
};
</script>
<style scoped></style>
