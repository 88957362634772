<template>
  <v-app id="error">
    <v-container fluid fill-height>
      <v-layout align-center justify-center row>
        <div class="text-md-center">
          <h1 class="my-3 headline">{{ $vuetify.lang.t("$vuetify.pageNotFound") }}</h1>
          <!--          <h1 class="my-3 headline">Sorry, this page doesn't exist.</h1>-->
        </div>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: "NotFoud",
  data() {
    return {};
  },
};
</script>

<style></style>
