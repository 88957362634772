<template>
  <v-app id="login">
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :bottom="snackbar.y === 'bottom'"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ $vuetify.lang.t(snackbar.text) }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar.show = false">
          {{ $vuetify.lang.t("$vuetify.close") }}
        </v-btn>
      </template>
    </v-snackbar>

    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4 lg4>
            <v-card class="elevation-1 pa-3">
              <v-card-text>
                <div class="layout column align-center">
                  <img :src="require('@/assets/logo.png')" alt="Point Of Sale" width="200" height="200" />
                </div>
                <v-form v-on:submit.prevent="submit" ref="form" v-model="valid" lazy-validation>
                  <v-text-field
                    append-icon="fa-user"
                    name="login"
                    label="Login"
                    type="text"
                    :rules="usernameRules"
                    v-model="username"
                    :error="error"
                  />
                  <v-text-field
                    :type="hidePassword ? 'password' : 'text'"
                    :append-icon="hidePassword ? 'fa-eye-slash' : 'fa-eye'"
                    name="password"
                    label="Password"
                    id="password"
                    :rules="passwordRules"
                    v-model="password"
                    :error="error"
                    required
                    @click:append="hidePassword = !hidePassword"
                  />
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :disabled="!valid" block color="primary" @click="login" :loading="loading">Login </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { userService } from "@/services";

export default {
  data() {
    return {
      loading: false,
      username: "",
      password: "",
      hidePassword: true,
      error: false,
      showResult: false,
      valid: false,
      snackbar: {
        show: false,
        y: "top", // or top
        x: null,
        multiLine: true,
        mode: "",
        timeout: 3000,
        text: "",
        color: "",
      },
    };
  },
  beforeMount() {
    userService.logout();
  },
  computed: {
    alertTrigger() {
      return this.$store.state.alert.trigger;
    },
    passwordRules: function () {
      return [(v) => !!v || this.$vuetify.lang.t("$vuetify.passwordRequired")];
    },
    usernameRules: function () {
      return [(v) => !!v || this.$vuetify.lang.t("$vuetify.usernameRequired")];
    },
  },
  watch: {
    alertTrigger(newState) {
      if (newState > 0) {
        this.snackbar.text = this.$vuetify.lang.t(this.$store.state.alert.message);
        this.snackbar.color = this.$vuetify.theme.currentTheme[this.$store.state.alert.color];
        this.snackbar.y = "bottom";
        this.snackbar.show = true;
        this.snackbar.mode = "vertical";
      } else {
        this.snackbar.show = false;
      }
    },
  },
  methods: {
    login() {
      this.$store.dispatch("loader/setLoading", true);

      let password = this.password;
      let username = this.username.trim();

      this.$store.dispatch("authentication/login", { username, password });
    },
  },
};
</script>

<style lang="sass">
#login
  height: 50%
  width: 100%
  position: absolute
  top: 0
  left: 0
  content: ""
  z-index: 0
</style>
