<template>
  <div>
    <!-- <p>{{ $route.params.tenant }} {{ $route.params.store }}</p> -->
    <router-view />
  </div>
</template>

<script>
//import { tenantServices } from "@/services";

import { setAxiosDefaultUrl } from "@/_helpers";

export default {
  name: "Tenant",
  data() {
    return {};
  },
  beforeMount() {
    // this.$log.debug("beforeMount");
    // this.$log.debug(this.$route.params.tenant);
    // this.$log.debug(this.$route.params.store);

    setAxiosDefaultUrl();

    //db.init(this.$route.params.tenant, this.$route.params.store);

    // TODO verificar se ambiente existe!??
    //   tenantServices
    //     .checkTenant(this.$route.params.tenant, this.$route.params.store, "check")
    //     .then(
    //       (result) => {
    //         //Vue.$log.debug("API URL:", url);
    //         //Vue.$log.debug("API URI:", uri);
    //         this.$log.debug("API CALL:", result.data);
    //         return result;
    //       },
    //       (error) => {
    //         this.$log.debug(error);
    //         this.$router.push({ name: "not-found", params: { 0: "home" } });
    //       }
    //     );
  },
};
</script>

<style scoped></style>
