<template>
  <v-app id="landing">
    <v-container fluid fill-height>
      <v-layout align-center justify-center row>
        <div class="layout column align-center">
          <img :src="require('@/assets/logo.png')" alt="Point Of Sale" width="200" height="200" />
        </div>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: "Landing",
  data() {
    return {};
  },
};
</script>

<style></style>
