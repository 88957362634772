<template>
  <v-navigation-drawer v-model="drawerClone" fixed clipped class="white lighten-1" app>
    <v-spacer class="my-4"></v-spacer>
    <div class="layout column align-center">
      <img :src="require('@/assets/logo.png')" alt="EasySalesPOS" width="auto" height="130" />
    </div>

    <v-spacer class="my-2"></v-spacer>

    <span v-if="time" class="d-flex justify-center">{{ formatTime(time) }}</span>

    <div v-if="cartSalesman" class="cart-panel-salesman">
      <v-list-item @click="displayDialogCheckoutCartSalesman">
        <v-list-item-icon>
          <v-icon>fa-user-tie</v-icon>
        </v-list-item-icon>

        <v-list-item-title>{{ cartSalesman.data.SalesmanShortName }}</v-list-item-title>
      </v-list-item>
    </div>

    <v-spacer v-if="cartSalesman" class="my-2"></v-spacer>

    <v-list class="pa-0">
      <v-list-item>
        <v-list-item-content class="pa-0">
          <v-select v-model="chosenLocale" :items="chosenLocaleOptions" :prepend-icon="chosenLocaleIcon">
            <template v-slot:item="slotProps">
              <i :class="['fi', `fi-${slotProps.item.flag}`]"></i>
              {{ slotProps.item.text }}
            </template>
          </v-select>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-list dense class="white lighten-1">
      <template v-for="(item, i) in menuItems">
        <v-layout v-if="i.heading && i.enabled" :key="i" row></v-layout>
        <v-divider v-else-if="item.divider" :key="i" class="my-1 grey lighten-2"></v-divider>
        <v-list-item
          v-else-if="item.enabled && !item.subgroups"
          :key="i"
          :to="item.route ? item.route : ''"
          @click="item.function ? handle_function_call(item.function) : ''"
          router
          exact
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item>

        <v-list-group v-if="item.subgroups" :key="i" no-action :value="true" :prepend-icon="item.icon">
          <template v-slot:activator>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-uppercase">{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-list-item v-for="subGroup in item.subgroups" :key="subGroup.id" :to="subGroup.route ? subGroup.route : ''">
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold primary--text">
                {{ subGroup.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { getApiBaseUrl, getErrorMessage } from "@/_helpers";
import { mapGetters } from "vuex";
import "flag-icons/css/flag-icons.min.css";
import { salesServices } from "@/services";

export default {
  name: "Menu",
  props: {
    drawer: Boolean,
    logout: Function,
  },
  data() {
    return {
      drawerClone: this.drawer,
      clipped: true,
      menuItems: [],
      catalog: [],
      chosenLocaleOptions: [],
      chosenLocaleIcon: null,
      // Variable that holds the sale datetime
      // (Only if the datetime is different from the server)
      time: null,
      // Interval that increments the time variable
      timeInterval: null,
    };
  },
  methods: {
    //@deprecated The Menu should not display the available Families
    // defineMenuItemGroup: function (menuGroup) {
    //   var menuItem = {
    //     icon: null,
    //     text: menuGroup.FamilyDescription,
    //     route:
    //       menuGroup.subFamilies && menuGroup.subFamilies > 0
    //         ? "/catalog/" + menuGroup.Id
    //         : "/catalog/detail/" + menuGroup.Id,
    //     enabled: true,
    //     id: menuGroup.Id,
    //   };
    //
    //   return menuItem;
    // },
    defineMenu() {
      this.$store.dispatch("loader/setLoading", false);

      //@deprecated The Menu should not display the available Families
      // if (this.families) {
      //   for (var i = 0; i < this.families.length; i++) {
      //     this.catalog.push(this.defineMenuItemGroup(this.families[i]));
      //   }
      // }

      this.menuItems.push({
        icon: "fal fa-home",
        text: this.$vuetify.lang.t("$vuetify.start"),
        route: { name: "home" },
        enabled: true,
        order: 1,
      });
      this.menuItems.push({
        icon: "fal fa-shopping-cart",
        text: this.$vuetify.lang.t("$vuetify.sales"),
        //@modified ana.castro 2021-08-12 2128
        //@modified ana.castro 2022.12.05 SAFO-47
        // route: { name: "catalog", params: { validateCashDrawer: true, salePriceRange: 1 } },
        route: { name: "catalog", params: { zone: 0, validateCashDrawer: true } },
        enabled: true,
        //@deprecated The Menu should not display the available Families
        //subgroups: this.catalog,
        order: 2,
      });
      this.menuItems.push({
        icon: "fal fa-shopping-basket",
        text: this.$vuetify.lang.t("$vuetify.suspendedSales"),
        route: { name: "sale-saved-carts" },
        enabled: true,
        order: 10,
      });
      this.menuItems.push({
        icon: "fal fa-file-search",
        text: this.$vuetify.lang.t("$vuetify.consultSales"),
        route: { name: "sales-history" },
        enabled: true,
        order: 15,
      });
      this.menuItems.push({
        icon: "fal fa-cart-arrow-down",
        text: "Fecho do Dia",
        route: "",
        enabled: false,
        order: 20,
      });
      this.menuItems.push({
        divider: true,
        order: 21,
      });
      this.menuItems.push({
        icon: "fal fa-address-card",
        text: this.$vuetify.lang.t("$vuetify.sessions"),
        route: { name: "salesmen-sessions" },
        enabled: true,
        order: 22,
      });
      this.menuItems.push({
        icon: "fal fa-cash-register",
        text: this.$vuetify.lang.t("$vuetify.cashDrawers"),
        route: { name: "cash-drawers-management" },
        enabled: true,
        order: 25,
      });
      this.menuItems.push({
        icon: "fal fa-chart-line",
        text: this.$vuetify.lang.t("$vuetify.reports"),
        route: { name: "reports" },
        enabled: true,
        order: 26,
      });
      this.menuItems.push({
        icon: "fal fa-cogs",
        text: this.$vuetify.lang.t("$vuetify.admin.administration"),
        route: { name: "administration" },
        enabled: true,
        order: 30,
      });
      this.menuItems.push({
        icon: "fal fa-warehouse",
        text: this.$vuetify.lang.t("$vuetify.backoffice"),
        function: "open_backoffice",
        enabled: true,
        order: 31,
      });
      this.menuItems.push({
        icon: "fal fa-sign-out-alt",
        text: this.$vuetify.lang.t("$vuetify.logout"),
        function: "logout_emit",
        enabled: true,
        order: 35,
      });

      //After the Default Sale's section, there might be other Sale's section depending on how many Sale's Zones
      // are active for the Store.
      //Therefore, to the Menu array it is necessary to add all the Sale's Zones which should be displayed
      // right after the Default Sale's section.
      // 1 - getting the active Zones
      // 2 - adding the Zone to the Menu's array
      // (the definition of the Route, includes the identification of the Sale's Zone code)
      // 3 - putting the Menu's array elements on the exact order that they should be displayed.
      // Using the "order" field.
      let orderCounter = 2;
      this.$store.getters["stores/getAllZones"].then(
        (result) => {
          if (result.length > 1) {
            //Removing the "Vendas" option, since there are Sale's Zones
            this.menuItems.splice(1, 1);
            for (var i = 0; i < result.length; i++) {
              this.menuItems.push({
                icon: "fal fa-shopping-cart",
                text: "Vendas " + result[i].description,
                //@modified ana.castro 2022.12.05 SAFO-47
                // route: {
                //   name: result[i].salesPriceRange === 1 ? "catalog" : "catalog" + result[i].salesPriceRange,
                //   params: { validateCashDrawer: true, zone: result[i].code },
                // },
                route: { name: "catalog", params: { zone: result[i].code, validateCashDrawer: true } },
                enabled: true,
                order: orderCounter,
              });
              orderCounter += 1;
            }

            this.menuItems.sort(function (a, b) {
              return a.order - b.order;
            });
          }
        },
        (error) => {
          getErrorMessage(error, this.$vuetify.lang.t("$vuetify.errorStoreZone"));
        }
      );
    },
    handle_function_call(function_name) {
      this[function_name]();
    },
    logout_emit() {
      this.$emit("logout");
    },
    open_backoffice() {
      window.open(getApiBaseUrl(), "_blank");
    },
    /**
     * Change Salesman Assigned to the Cart Implementation
     */
    displayDialogCheckoutCartSalesman() {
      this.$store.dispatch("salesmen/setDisplaySelectSalesman", { status: true });
    },
    getServerTime() {
      salesServices.getServerTime().then(
        (result) => {
          const localTime = Date.now() / 1000;
          // Give a difference margin of 2 seconds
          if (Math.abs(result - localTime) > 2) {
            this.time = (this.useLocalTime ? localTime : result) * 1e3;

            if (!this.timeInterval) this.timeInterval = setInterval(() => (this.time += 1e3), 1000);
          } else {
            this.time = null;
          }
        },
        (error) => {
          getErrorMessage(error, "Failed to get Server Time", this);
        }
      );
    },
    formatTime(timestamp) {
      return new Date(timestamp).toLocaleString("pt-PT");
    },
  },
  watch: {
    drawer() {
      this.drawerClone = this.drawer;
    },
    drawerClone() {
      if (this.drawerClone !== this.drawer) {
        this.$emit("changeDrawer", this.drawerClone);
      }
    },
    chosenLocale() {
      this.$vuetify.lang.current = this.chosenLocale;
      this.chosenLocaleIcon = "fi fi-" + this.$vuetify.lang.t("$vuetify.languageFlag");
      location.reload();
    },
    useLocalTime() {
      this.getServerTime();
    },
  },

  computed: {
    /**
     * This function defines the content of the Menu.
     * @returns {[]}
     */
    getMenuItems: function () {
      return this.menuItems;
    },
    chosenLocale: {
      get: function () {
        return this.$store.getters["configs/chosenLocale"];
      },
      set: function (newValue) {
        this.$store.dispatch("configs/setChosenLocale", newValue);
      },
    },
    useLocalTime: {
      get: function () {
        return this.$store.getters["configs/useLocalTime"];
      },
    },
    ...mapGetters({
      cartSalesman: "salesmen/getSelectedSalesman",
    }),
  },
  mounted() {
    this.defineMenu();

    this.localeChoices = this.$vuetify.lang.locales;
    if (!this.chosenLocale) {
      this.chosenLocale = navigator.language.substring(0, 2).toLowerCase();
    }
    this.chosenLocaleOptions = Object.entries(this.localeChoices).map((l) => ({
      value: l[0],
      text: l[1]["languageName"],
      flag: l[1]["languageFlag"],
    }));
    if (this.chosenLocale) {
      this.chosenLocaleIcon = "fi fi-" + this.$vuetify.lang.t("$vuetify.languageFlag");
    }

    this.getServerTime();
  },
};
</script>

<style scoped></style>
