<template>
  <v-app>
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :bottom="snackbar.y === 'bottom'"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ $vuetify.lang.t(snackbar.text) }}

      <template v-slot:action="{ attrs }">
        <v-btn color="indigo" text v-bind="attrs" @click="snackbar.show = false">
          {{ $vuetify.lang.t("$vuetify.close") }}
        </v-btn>
      </template>
    </v-snackbar>

    <Menu :drawer="drawer" @changeDrawer="changeDrawer" @logout="logout"></Menu>
    <Cart v-if="displayCart" :drawer="drawerCart" @changeDrawer="changeDrawerCart"></Cart>

    <v-app-bar color="white" :clipped-left="clipped" :clipped-right="clipped" fixed app>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />

      <v-text-field
        v-if="displayCart"
        outlined
        class="catalog-search-input"
        :label="$vuetify.lang.t('$vuetify.search')"
        append-icon="fal fa-search"
        ref="searchInput"
        v-click-outside="clearSearch"
        v-model="searchText"
        clear-icon="fal fa-times"
        clearable
        hide-details
        type="text"
        full-width
        small
        @click:append="applySearch"
        @click:clear="clearSearch"
        @keydown.enter="applySearch"
      ></v-text-field>
      <!--      <v-toolbar-title v-else :v-text="$vuetify.lang.t($route.meta.toolbarTitle)" />-->
      <v-toolbar-title v-else>
        <div>
          {{ $vuetify.lang.t($route.meta.toolbarTitle) }}
        </div>
      </v-toolbar-title>

      <v-spacer />

      <v-btn v-if="displayCart" icon @click.stop="drawerCart = !drawerCart">
        <v-icon>fa-shopping-cart</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-container fluid grid-list-md>
        <v-sheet v-if="displayFamiliesSheet" class="mx-auto">
          <v-slide-group
            class="catalog-families-slide pl-md-5 pr-md-5 pr-lg-10 pl-xl-10 pr-xl-15"
            v-model="vsheetSelectedFamilyPosition"
            show-arrows
          >
            <v-slide-item v-for="(item, i) in families" :key="i" v-slot="{ active, toggle }">
              <v-btn
                class="mx-1"
                :input-value="active"
                color="white"
                active-class="primary white--text"
                depressed
                large
                @click="toggle"
              >
                {{ item.data.FamilyDescription }}
              </v-btn>
            </v-slide-item>
          </v-slide-group>
          <v-spacer class="my-2"></v-spacer>
        </v-sheet>

        <router-view />
      </v-container>
    </v-main>
    <v-dialog v-model="displayDialogLogout" persistent max-width="250px">
      <ConfirmAction
        :title="confirmLogoutTitle"
        @close-dialog="closeDialogLogout"
        @confirm-action="confirmLogout"
      ></ConfirmAction>
      <!--      <v-card>-->
      <!--        <v-card-title class="title">{{-->
      <!--          $vuetify.lang.t("$vuetify.authentication.end_session")-->
      <!--        }}</v-card-title>-->
      <!--        <v-card-actions>-->
      <!--          <v-spacer></v-spacer>-->
      <!--          <v-btn color="primary" text @click="confirmLogout">Ok</v-btn>-->
      <!--        </v-card-actions>-->
      <!--      </v-card>-->
    </v-dialog>
    <v-dialog v-model="displayDialogCheckoutCartSalesman" persistent max-width="600px">
      <SalesmenList
        v-if="displayDialogCheckoutCartSalesman"
        :title="salesmanListTitle"
        @set-sale-salesman="setSaleSalesman"
      ></SalesmenList>
    </v-dialog>

    <Keypress key-event="keyup" :multiple-keys="multiple" @success="keypressAction" />
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import Menu from "@/components/Menu.vue";
import Cart from "@/components/Cart.vue";
import ConfirmAction from "@/components/ConfirmAction.vue";
import SalesmenList from "@/components/SalesmenList.vue";
import { updateDatabase } from "@/_helpers";
import Vue from "vue";

export default {
  name: "AppLayout",
  components: { Menu, Cart, ConfirmAction, SalesmenList, Keypress: () => import("vue-keypress") },
  data() {
    return {
      multiple: [],
      families: [],
      vsheetSelectedFamilyPosition: -1,
      clipped: true,
      drawer: false,
      drawerCart: false,
      fixed: true,
      absolute: true,
      miniVariant: false,
      right: true,
      title: this.$route.meta.toolbarTitle,
      searchInputVisible: false,
      searchText: "",
      displayDialogLogout: false,
      confirmLogoutTitle: null,
      selectedFamilyId: null,
      salesmanListTitle: null,
      snackbar: {
        show: false,
        y: "top", // or top
        x: null,
        multiLine: true,
        mode: "",
        timeout: 3000,
        text: "",
        color: "",
      },
    };
  },
  beforeCreate() {
    this.$store.commit("configs/setGeneralConfig");
    this.$store.commit("cart/initialiseStore");
    //this.$store.dispatch("dataLog/synchronizeDataLog");
    updateDatabase(this);

    //@modified ana.castro 2022.06.07 SAFO-31
    //https://dexie.org/docs/StorageManager
    async function persist() {
      return (await navigator.storage) && navigator.storage.persist && navigator.storage.persist();
    }
    //Caveats
    // Some things to consider: your app must be served over HTTPS,
    // as the StorageManager() is only available in a secure context.
    persist();

    //Two functions for debugging
    async function isStoragePersisted() {
      return (await navigator.storage) && navigator.storage.persisted && navigator.storage.persisted();
    }

    async function showEstimatedQuota() {
      if (navigator.storage && navigator.storage.estimate) {
        const estimation = await navigator.storage.estimate();
        // console.log(estimation);
        Vue.$log.info(`Quota: ${estimation.quota}`);
        Vue.$log.info(`Usage: ${estimation.usage}`);
      } else {
        Vue.$log.info("StorageManager not found");
      }
    }

    isStoragePersisted().then(async (isPersisted) => {
      if (isPersisted) {
        Vue.$log.info(":) Storage is successfully persisted.");
      } else {
        Vue.$log.info(":( Storage is not persisted.");
        Vue.$log.info("Trying to persist..:");
        if (await persist()) {
          Vue.$log.info(":) We successfully turned the storage to be persisted.");
        } else {
          Vue.$log.info(":( Failed to make storage persisted");
        }
      }
    });
    showEstimatedQuota();

    //Chrome feature to ask for more Quota
    //https://developer.chrome.com/docs/apps/offline_storage/#managing_quota
    // Request Quota (only for File System API)
    // var requestedBytes = 1024 * 1024 * 94;
    // var requestedBytes = 1024 * 1024 * 807; // 10MB
    var requestedBytes = 500 * 1024 * 1024; // 500MB

    //navigator.webkitPersistentStorage.queryUsageAndQuota (
    navigator.webkitTemporaryStorage.queryUsageAndQuota(
      function (usedBytes, grantedBytes) {
        Vue.$log.info("we are using ", usedBytes, " of ", grantedBytes, "bytes");
      },
      function (e) {
        Vue.$log.info("Error", e);
      }
    );

    //Chrome presents an info bar that prompts the user to grant the app more local storage space.
    window.requestFileSystem = window.requestFileSystem || window.webkitRequestFileSystem;
    // @modified ana.castro 2023.02.22: Cannot read properties of undefined (reading 'requestQuota')
    // window.webkitStorageInfo.requestQuota(
    //   window.PERSISTENT,
    //   requestedBytes,
    //   function (bytes) {
    //     Vue.$log.info("Quota is available: " + bytes);
    //   },
    //   function (e) {
    //     Vue.$log.error("Error allocating quota: " + e);
    //   }
    // );
    //Function definition
    // navigator.webkitPersistentStorage.requestQuota (
    //   newQuotaInBytes, // The amount of bytes you want in your storage quota.
    //   quotaCallback, // Optional callback that passes the amount of bytes granted.
    //   errorCallback); //Optional error callback.
    navigator.webkitPersistentStorage.requestQuota(
      requestedBytes,
      function (bytes) {
        Vue.$log.info("Quota is available: " + bytes);
      },
      function (e) {
        Vue.$log.error("Error allocating quota: " + e);
      }
    );
  },
  methods: {
    closeDialogLogout() {
      this.displayDialogLogout = false;
    },
    logout() {
      this.displayDialogLogout = true;
    },
    confirmLogout() {
      this.$store.dispatch("authentication/logout");
      this.$router.push({ name: "login" });
    },
    changeDrawer: function (drawer) {
      this.drawer = drawer;
    },
    changeDrawerCart: function (drawerCart) {
      this.drawerCart = drawerCart;
    },
    applySearch() {
      this.$router
        .push({
          name: "catalog-search-results",
          query: { search: this.searchText },
        })
        .catch(() => {
          /* NavigationDuplicated if same search term used */
        });
      this.clearSearch();
    },
    clearSearch() {
      this.$nextTick(() => {
        this.searchInputVisible = false;
        this.searchText = "";
      });
    },
    focusSearchInput() {
      this.$refs.searchInput.focus();
    },
    getFamilies(pk) {
      let catalogEntryIdentifier = parseInt(pk);

      if (!catalogEntryIdentifier) {
        // this.$store.getters["families/getMain"].then((result) => {
        this.$store.dispatch("families/getMain").then((result) => {
          this.families = result;
          this.vsheetSelectedFamilyPosition = this.families.findIndex((family) => family.id === this.selectedFamilyId);
        });
      } else {
        this.$store.dispatch("families/get", catalogEntryIdentifier).then((result) => {
          let catalogEntryCode = result.code;
          this.$store.dispatch("families/getChildren", catalogEntryCode).then((result) => {
            this.families = result;
            this.vsheetSelectedFamilyPosition = this.families.findIndex(
              (family) => family.id === this.selectedFamilyId
            );
          });
        });
      }
    },
    /**
     * This method is called by the SalesmenList when it emits the event "set-sale-salesman".
     * It aims at setting the chosen Salesman assigned to the Cart.
     * @param {object} salesman
     */
    setSaleSalesman(salesman) {
      this.$store.dispatch("salesmen/setSelectedSalesman", salesman);
      let redirect = this.redirectPostSelectSalesman;

      let parameters = this.redirectPostSelectSalesmanParameters;

      if (parameters) {
        parameters["selectedSalesman"] = true;
      } else {
        parameters = { selectedSalesman: true };
      }

      this.$store.dispatch("salesmen/setDisplaySelectSalesman", { status: false });
      //After choosing the Salesman, the User must be redirected to the Catalog interface,
      this.$router.push({
        name: redirect,
        //@modified ana.castro 2022.12.05 SAFO-47
        // params: { validateCashDrawer: true },
        params: parameters,
      });
    },
    /**
     * This function is used by the keypress component. It is called everytime the user types a key.
     * If the user is in a Sale view, this function sets the focus on the Search element and defines the Search Term
     * according to what the user is typing.
     * @param response
     */
    keypressAction(response) {
      var inputs = ["input", "select", "textarea"];

      //(1) If the user is in a Sale View
      //(2) and If the focus is not already in an input of type "input", "select", "textarea"
      //(3) and the pressed keys are numbers or letters (excluding shift, tab...)
      //Then the "searchInput" element is set as the focus element
      // and the written keys are set to the Search Text variable
      if (
        (this.$route.meta.context === "sale" || this.$route.meta.context === "sale-return") &&
        inputs.indexOf(response.event.target.tagName.toLowerCase()) === -1 &&
        ((response.event.keyCode >= 65 && response.event.keyCode <= 90) ||
          (response.event.keyCode >= 48 && response.event.keyCode <= 57) ||
          (response.event.keyCode >= 96 && response.event.keyCode <= 105))
      ) {
        this.$nextTick(() => {
          this.focusSearchInput();
          this.searchText = response.event.key;
        });
      }
    },
  },
  computed: {
    alertTrigger() {
      return this.$store.state.alert.trigger;
    },
    displaySearchInput: function () {
      return this.searchInputVisible;
    },
    displayCart: function () {
      return this.$route.meta.context === "sale" || this.$route.meta.context === "sale-return";
    },
    //daniel.silva temporário tiger
    displayFamiliesSheet: function () {
      return (
        this.$route.meta.context === "sale" &&
        this.$route.name !== "catalog" &&
        // this.$route.name !== "catalog2" &&
        // this.$route.name !== "catalog3" &&
        // this.$route.name !== "catalog4" &&
        // this.$route.name !== "catalog5" &&
        this.$route.name !== "shop-cart-checkout-return-details" &&
        this.$route.name !== "shop-cart-checkout-return" &&
        this.$route.name !== "shop-cart-selfcheckout-details-client" &&
        this.$route.name !== "shop-cart-selfcheckout-details-payment"
      );
    },
    ...mapGetters({
      vsheetFamilyStored: "families/getSelectedFamily",
      familiesLastFetch: "families/getFamiliesLastFetch",
      cartSalesman: "salesmen/getSelectedSalesman",
      displayDialogCheckoutCartSalesman: "salesmen/isToDisplaySelectSalesman",
      redirectPostSelectSalesman: "salesmen/getRedirectPostSelectSalesman",
      redirectPostSelectSalesmanParameters: "salesmen/getRedirectPostSelectSalesmanParameters",
      cartZone: "cart/cartZone",
    }),
  },
  watch: {
    alertTrigger(newState) {
      if (newState > 0) {
        this.snackbar.text = this.$vuetify.lang.t(this.$store.state.alert.message);
        this.snackbar.color = this.$vuetify.theme.currentTheme[this.$store.state.alert.color];
        this.snackbar.y = "bottom";
        this.snackbar.show = true;
        this.snackbar.mode = "vertical";
      } else {
        this.snackbar.show = false;
      }
    },
    vsheetSelectedFamilyPosition() {
      if (this.vsheetSelectedFamilyPosition !== undefined && this.vsheetSelectedFamilyPosition !== -1) {
        if (this.families[this.vsheetSelectedFamilyPosition].id !== parseInt(this.$route.params.id)) {
          if (this.families[this.vsheetSelectedFamilyPosition].subFamilies) {
            let code = this.families[this.vsheetSelectedFamilyPosition].code;
            let offset = 0;
            let limit = 2;
            this.$store.dispatch("families/getChildren", { code, offset, limit }).then((result) => {
              if (result.length === 1) {
                this.$store.dispatch("families/setSelectedFamily", this.families[this.vsheetSelectedFamilyPosition].id);
                this.$router.push({ name: "catalog-detail-items", params: { detail: result[0].id } }).catch(() => true);
              } else {
                this.$router
                  .push({
                    name: "catalog-detail",
                    params: { id: this.families[this.vsheetSelectedFamilyPosition].id, zone: this.cartZone.code },
                  })
                  .catch(() => true);
              }
            });
          } else {
            //Attention: nunca vai cair aqui porque para já na v-sheet só estou a apresentar Famílias Nível 1
            this.$router.push({
              name: "catalog-detail-items",
              params: {
                detail: this.families[this.vsheetSelectedFamilyPosition].id,
              },
            });
          }
        }
      } else {
        if (this.$route.name !== "catalog") {
          //@modified ana.castro 2022.12.06 SAFO-47
          // this.$router.push({name: "catalog", });
          this.$router.push({ name: "home" });
        }
      }
    },
    vsheetFamilyStored() {
      this.vsheetSelectedFamilyPosition = this.families.findIndex((family) => family.id === this.vsheetFamilyStored);
    },
    familiesLastFetch(val, old) {
      if (val !== old) {
        this.getFamilies();
      }
    },
  },
  mounted() {
    this.$store.subscribe((mutation) => {
      switch (mutation.type) {
        case "configs/setGeneralConfig":
          break;
      }
    });

    this.selectedFamilyId = parseInt(this.$route.params.id);
    if (this.vsheetSelectedFamilyPosition === undefined || this.vsheetSelectedFamilyPosition === -1) {
      this.$store.dispatch("families/setSelectedFamily", null);
    } else {
      this.$store.dispatch("families/setSelectedFamily", this.selectedFamilyId);
    }

    this.getFamilies();

    this.salesmanListTitle = this.$vuetify.lang.t("$vuetify.selectSalesman");
    this.confirmLogoutTitle = this.$vuetify.lang.t("$vuetify.confirmLogout");
  },
};
</script>
<!--TODO Mudar isto para saas-->
<style lang="stylus">

#keep
  .v-navigation-drawer__border
    display: none

#content-app-ui
  background: #ffffff
  color: #000000

.v-main
  background-color: #eef1f5

.v-toolbar__content .v-btn--icon, .v-toolbar__extension .v-btn--icon
  margin: 0

.v-toolbar, .v-toolbar.primary
  color: #028940 !important
  border-color: #028940 !important

.v-toolbar,
.v-toolbar .v-icon,
.v-toolbar .v-input,
.v-toolbar .v-input input
  color: #028940 !important
  caret-color: #028940 !important

.v-toolbar .v-text-field--box input
  margin-top: 16px
  font-size: 21px

.v-toolbar .v-input input::placeholder
  color: #c0c0c0 !important
  opacity: 1

.v-navigation-drawer
  .v-list-item--dense, .v-list--dense .v-list-item
    min-height: 50px;


.v-navigation-drawer .v-list__tile__title
.v-navigation-drawer .v-list__group__header__append-icon .v-icon
  color: #000 !important

.v-navigation-drawer .v-list__group__items .v-list__tile__title
  font-weight 400 !important

input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button
  -webkit-appearance: none

.v-expansion-panel
  box-shadow: none

.v-expansion-panel__header
  padding: 0

.v-expansion-panel .v-expansion-panel__container .v-expansion-panel__header .v-expansion-panel__header__icon .v-icon
  font-weight: 100

.v-list__group__header__append-icon .v-icon
  font-weight: 100;

.v-toolbar__content .v-btn--icon.v-btn--right
  margin-left: 10px

.v-bottom-navigation {
  justify-content: start;
  overflow-x: scroll;
}

.alert-list-tile
  .v-icon
    font-weight bold

  .v-list__tile__title
    font-weight 500
    text-align center
    overflow: visible;
    white-space: normal;
    height: auto;

  .v-btn--flat
    font-weight bold


.item-card-initials
  font-size: 21px;
  margin-left: 8px;

/* CATALOG CSS*/

.theme--dark.v-sheet.catalog-group-card
  background-color: #424242;

.catalog-group-card
  background-color: transparent
  cursor: pointer

  .v-card__title
    display: block;

  .headline
    width: 100%
    text-align: center
    font-size: 20px !important
    font-weight: 600;


.catalog-group-card.no-card-image
  min-height: 150px

  .v-card__title
    padding-top: 60px;

.catalog-item-card
  background-color: transparent
  margin: 2px

  .v-image
    height: 130px;

  .headline
    font-size: 14px !important
    height: 40px
    overflow: hidden
    font-weight: 400
    line-height: 20px
    width: 100%

  .v-card__title
    padding: 5px
    text-align center
    border-top: 1px solid rgba(0, 0, 0, 0.12)

  .catalog-item-price
    font-size: 25px
    font-weight: 400

  .item-card-initials
    font-size: 35px
    font-weight: 300
    position absolute
    width: 100%
    height 100%
    line-height 130px
    text-align center

  .item-card-code
    position: absolute
    top: 10px
    font-weight: 100
    width: 100%
    text-align: center
    font-size: 14px

  .item-card-options
    position: absolute
    width: 100%
    text-align: right

.catalog-group-card:hover, .catalog-item-card:hover
  .headline
    text-decoration: underline

.catalog-item-title
  font-weight 600

.catalog-item-image-promo
  position: absolute
  top: 5px
  right: 5px
  padding: 7px 9px
  border-radius: 7px

@media only screen and (max-width: 768px)
  .catalog-item-menu-level-panel
    .v-list__tile, .v-list
      padding: 0px

      .v-list__tile__avatar
        min-width: auto

      .v-list__tile__title
        font-size: 14px

.catalog-search-input
  margin-top: 0px

.catalog-families-slide
  background-color: #eef1f5

  .v-btn
    height: 60px !important

.salesmans-list
  .v-list-item-group .v-list-item--active
    color #2196f3 !important

.cart-panel
  top: 95px !important
  max-height: calc(100% - 135px) !important
  border-bottom: 1px solid rgba(0, 0, 0, 0.12)
  border-top: 1px solid rgba(0, 0, 0, 0.12)

  .cart-panel-salesman
    text-align left

    .v-icon, .v-list-item__title
      color #2196f3 !important

    .v-icon
      margin-left: 10px

    .v-list-item__title
      font-size 20px

    .v-list-item__title:hover
      text-decoration underline

  .cart-items-list
    .discounted-price
      text-decoration: line-through

    .discount-percent
      font-weight bold
      color #d32f2f

  .cart-panel-items
    margin-bottom: 135px

    .v-list-item__title
      font-size 14px;

  .cart-panel-actions
    width 100%
    height: 165px
    position: fixed
    bottom: 0px
    padding-top: 5px
    padding-left: 20px;
    padding-right: 20px;
    background-color #fff

    .cart-panel-actions-menu
      min-width: 30px !important;
      width: 30px !important;

    .cart-panel-pay
      color: #ffffff !important

      .v-btn__content
        display block

      .amount
        float right
        font-weight: 600
        font-size: 20px

      small
        float left
        font-weight 400
        font-size: 20px

  .cart-panel-items.checkout
    margin-bottom: 200px

  .cart-panel-actions.checkout
    height: 230px

  .cart-item-quantity-input
    input
      font-size: 14px;

  .cart-panel-alert-sale-properties-alert
      text-align: center !important;
      font-weight bold
      color #d32f2f

  .cart-panel-alert-sale-properties-info
      text-align: center !important;
      font-weight bold
      color #028940


.cart-item-quantity-input
  .v-text-field.v-input--is-disabled .v-input__slot::before
    border 0px !important

  input
    text-align: center;
    font-weight: bold;
    font-size: 16px;

.checkout-customer-panel, .checkout-payment-panel
  .col-12
    padding-bottom: 0px !important;
    padding-top: 0px !important;


.cart-item-quantity-input .v-input__slot
  text-align: center !important;
  display: block;

@media only screen and (max-width: 768px)
  .cart-panel
    top: 0px !important
    max-height: 100% !important
</style>
